import React, { ReactElement } from 'react'
import { useCMSHomepageVariant } from '@mindfulchefuk/features/CMS/hooks/useCMSHomepageVariant'
import { EmphasisLinebreakText } from '@mindfulchefuk/features/Homepage/components/EmphasisLinebreakText'
import {
  WaveIllustrationWrapper,
  WaveIllustrationWrapperProps,
} from '@mindfulchefuk/design-system/layout/WaveIllustrationWrapper'

export const OneFeedsTwoStats = ({
  waveIllustrationWrapper,
}: {
  waveIllustrationWrapper?: WaveIllustrationWrapperProps
}): ReactElement => {
  const { oneFeedsTwoText, oneFeedsTwoEmphasisText } = useCMSHomepageVariant()

  return (
    <WaveIllustrationWrapper
      {...{
        waveTop: 2,
        waveBototm: 2,
        bgColor: 'salt',
        margin: '24px 0 16px',
        ...waveIllustrationWrapper,
      }}
    >
      <EmphasisLinebreakText
        as="h2"
        px={48}
        py={12}
        mx="auto"
        textAlign="center"
        maxWidth="600px"
        text={oneFeedsTwoText}
        emphasisText={oneFeedsTwoEmphasisText}
        variant="heading-md"
        emphasisVariant="heading-lg"
      />
    </WaveIllustrationWrapper>
  )
}
