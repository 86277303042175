import Cookies from 'universal-cookie'

export const removeOnboardingCookie = () => {
  const cookies = new Cookies()
  const onboardingCookie = cookies.get('customerOnboarding')

  if (onboardingCookie) {
    cookies.remove('customerOnboarding', { path: '/' })
  }
}
