import { Props as IllustrationProps } from '@mindfulchefuk/design-system/Illustrations/Illustrations'
import { useCMSPage } from '@mindfulchefuk/features/CMS/hooks/useCMSPage'
import { CMSPageHomepage } from '@mindfulchefuk/features/CMS/interfaces'
import { layers } from '@mindfulchefuk/features/Homepage/components/Hero/config'

type IllustrationSeason = {
  heroTitleRight: IllustrationProps[]
  heroPlatesLeft: IllustrationProps[]
  heroPlatesRight: IllustrationProps[]
}

type IllustrationSet = {
  [key in CMSPageHomepage['theme']]: IllustrationSeason
}

export const useHomepageThemeIllustrations = (): IllustrationSeason => {
  const { data } = useCMSPage('page-homepage')

  if (!data?.theme) {
    return {
      heroTitleRight: [],
      heroPlatesLeft: [],
      heroPlatesRight: [],
    }
  }

  return seasonalIllustrations[data.theme]
}

const defaultStyles = {
  transform: {
    base: 'scale(0.6)',
    sm: 'scale(0.7)',
    md: 'scale(1)',
  },
  zIndex: layers.illustrations,
}

export const seasonalIllustrations: IllustrationSet = {
  spring: {
    heroTitleRight: [
      {
        ...defaultStyles,
        display: { base: 'none', sm: 'block' },
        position: 'absolute',
        right: { base: 20, md: '50px' },
        top: { sm: -16, md: 0 },
        transformOrigin: 'center right',
        type: 'hp-carrot-chopped-2',
      },
    ],
    heroPlatesLeft: [
      {
        ...defaultStyles,
        display: { base: 'none', sm: 'block' },
        left: { base: '-102px' },
        position: 'absolute',
        top: { base: -24, sm: -36, lg: -8 },
        transform: {
          base: 'scale(0.6)',
          sm: 'scale(0.7)',
          md: 'scale(.8)',
        },
        type: 'hp-broccoli-2',
        zIndex: 1,
      },
      {
        ...defaultStyles,
        display: { base: 'none', md: 'block' },
        left: { md: '-12px' },
        top: { md: 48, lg: '92px' },
        transform: {
          md: 'scale(.8) rotate(24deg)',
        },
        type: 'hp-carrot-chopped-1',
      },
    ],
    heroPlatesRight: [
      {
        ...defaultStyles,
        bottom: { base: -10, sm: 'unset' },
        right: { base: '-86px', md: '-102px' },
        top: { sm: -8, md: -24 },
        transform: {
          base: 'rotate(-85deg) scale(0.6) scaleX(-1)',
          sm: 'rotate(-77deg) scale(.7) scaleX(-1)',
          md: 'rotate(-77deg) scale(.9) scaleX(-1)',
        },
        type: 'hp-broccoli-1',
        zIndex: 4,
      },
      {
        ...defaultStyles,
        bottom: { base: -24, sm: 'unset' },
        right: { base: '-39px' },
        top: { sm: 80, md: 72 },
        type: 'hp-radish-slice-1',
      },
    ],
  },
  winter: {
    heroTitleRight: [],
    heroPlatesLeft: [
      {
        ...defaultStyles,
        display: 'block',
        position: 'absolute',
        right: { base: '82vw', sm: -64, md: -36, lg: 8 },
        top: { base: -48, sm: 16, lg: 32 },
        transform: {
          base: 'rotate(41deg)  scale(0.5)',
          sm: 'rotate(1.39deg)  scale(0.5)',
          md: 'rotate(1.39deg)  scale(0.7)',
        },
        type: 'cross-section-runner-bean-2',
        zIndex: { base: 3, md: 1 },
      },
      {
        ...defaultStyles,
        display: { base: 'none', sm: 'block' },
        left: { sm: -32, md: 16, lg: '64' },
        position: 'absolute',
        top: { sm: 64, md: 96, lg: '112px' },
        transform: {
          sm: 'rotate(-5deg) scale(0.6)',
          md: 'rotate(-14deg) scale(1.1)',
        },
        type: 'cross-section-brussels-sprout',
        zIndex: 1,
      },
      {
        ...defaultStyles,
        display: { base: 'none', sm: 'block' },
        left: { sm: -40, lg: 4 },
        position: 'absolute',
        top: { sm: 0, md: 32 },
        transform: {
          sm: 'rotate(15deg) scale(0.6)',
          md: 'rotate(-1.6deg) scale(1.1)',
        },
        type: 'cross-section-cranberries',
      },
    ],
    heroPlatesRight: [
      {
        ...defaultStyles,
        bottom: { base: 48, sm: 0 },
        display: 'block',
        position: 'absolute',
        right: { base: -48, sm: -32, md: -24, lg: 56 },
        top: { sm: '86px', md: '117px', lg: '140px' },
        transform: {
          base: 'rotate(-26deg) scale(0.8)',
          sm: 'rotate(-40deg) scale(0.6)',
          md: 'rotate(-40deg) scale(1.1)',
        },
        type: 'cross-section-cranberries',
      },
      {
        ...defaultStyles,
        bottom: { base: '26px' },
        display: { base: 'block', sm: 'none' },
        position: 'absolute',
        right: { base: -24, sm: -32 },
        transform: {
          base: 'rotate(-26deg) scale(0.8)',
          sm: 'rotate(-40deg) scale(0.6)',
        },
        type: 'cross-section-brussels-sprout',
        zIndex: 1,
      },
    ],
  },
}
