import React, { ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useRouter } from 'next/router'

import { Box, Text, Container } from '@mindfulchefuk/design-system'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'

import CTA from '@mindfulchefuk/features/Homepage/components/CTA/CTA'
import { useGetHomepageRecipes } from '@mindfulchefuk/features/Recipes/hooks/useGetHomepageRecipes'
import { WeeklyRecipeCarousel } from '@mindfulchefuk/features/Homepage/components/WeeklyRecipes/WeeklyRecipesCarousel'
import 'swiper/swiper.min.css'
import { EmphasisLinebreakText } from '@mindfulchefuk/features/Homepage/components/EmphasisLinebreakText'
import { CTALocation } from '@mindfulchefuk/analytics'
import {
  WaveIllustrationWrapper,
  WaveIllustrationWrapperProps,
} from '@mindfulchefuk/design-system/layout/WaveIllustrationWrapper'

export const WeeklyRecipes = ({
  ctaLocation,
  title,
  titleEmphasis,
  subtitle,
  subtitleEmphasis,
  waveIllustrationWrapper,
}: {
  title: string
  titleEmphasis?: string
  subtitle?: string
  subtitleEmphasis?: string
  ctaLocation?: CTALocation
  waveIllustrationWrapper?: WaveIllustrationWrapperProps
}): ReactElement => {
  const { asPath } = useRouter()

  const isVeganSEOPage = asPath === '/vegan-recipe-boxes'

  const { data: recipes = [] } = useGetHomepageRecipes({
    mealPlan: isVeganSEOPage ? 'Plant-Based' : 'Balanced',
    portionCount: 2,
  })

  return (
    <WaveIllustrationWrapper
      {...{
        waveTop: 3,
        waveBottom: 2,
        bgColor: 'white',
        contentMaxWidth: 'none',
        ...waveIllustrationWrapper,
      }}
    >
      <Box as="section">
        <Container maxWidth="800px" pt={24}>
          <EmphasisLinebreakText
            as="h2"
            variant="heading-md"
            textAlign="center"
            mb={12}
            text={title}
            emphasisText={titleEmphasis}
            emphasisVariant="heading-lg"
          />

          {subtitle && (
            <EmphasisLinebreakText
              as="p"
              variant={{ base: 'body-md', sm: 'body-lg' }}
              textAlign="center"
              text={subtitle}
              emphasisText={subtitleEmphasis}
              emphasisVariant={{ base: 'body-md-500', sm: 'body-lg-500' }}
            />
          )}
        </Container>
        <Box py={12} minHeight={{ base: '285px', md: '320px' }}>
          {recipes.length ? (
            <AnimatePresence>
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <WeeklyRecipeCarousel recipes={recipes} />
              </motion.div>
            </AnimatePresence>
          ) : null}
        </Box>
        <Box mt={12} textAlign="center" maxWidth={256} mx="auto">
          <CTA
            analytics={{
              category: ANALYTICS_CATEGORIES.homepage,
              action: 'Recipe Carousel CTA clicked',
              label: 'Choose recipe button clicked',
            }}
            source={ctaLocation}
            showCopy={false}
          />
          <Text
            as="p"
            mt={12}
            px={12}
            variant={{ base: 'body-sm', md: 'body-md' }}
          >
            Fully flexible subscription, skip or pause anytime
          </Text>
        </Box>
      </Box>
    </WaveIllustrationWrapper>
  )
}
