import React, { ReactElement, VoidFunctionComponent } from 'react'
import { Box, CONTAINER_MAX_WIDTH, Text } from '@mindfulchefuk/design-system'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'
import { useHomepageThemeIllustrations } from '@mindfulchefuk/features/Homepage/hooks/useHomepageThemeIllustrations'
import { layers } from '@mindfulchefuk/features/Homepage/components/Hero/config'
import { EmphasisLinebreakText } from '@mindfulchefuk/features/Homepage/components/EmphasisLinebreakText'

interface PlateSectionBackgroundProps {
  hideIllustrations?: boolean
  additionalContent?: ReactElement
  heroTitle: string
  heroTitleEmphasis: string
  heroByline: string
}

export const HeroTopSection: VoidFunctionComponent<
  PlateSectionBackgroundProps
> = ({
  hideIllustrations,
  additionalContent = null,
  heroByline,
  heroTitle,
  heroTitleEmphasis,
}) => {
  const { heroTitleRight } = useHomepageThemeIllustrations()

  return (
    <Box backgroundColor="aubergine">
      <Box
        maxWidth={CONTAINER_MAX_WIDTH}
        mx="auto"
        position="relative"
        zIndex={layers.topSection}
      >
        {additionalContent}
        <Box textAlign="center" color="white" pb={{ base: 8, xl: 36 }} px={36}>
          <EmphasisLinebreakText
            id="hero-heading"
            as="h1"
            text={heroTitle}
            emphasisText={heroTitleEmphasis}
            variant={{
              base: 'heading-md',
              xl: 'heading-lg',
            }}
            emphasisVariant="heading-lg"
          />
          {heroByline && <Text variant="body-md">{heroByline}</Text>}
        </Box>

        {!hideIllustrations
          ? heroTitleRight.map((illustrationConfig) => {
              return (
                <Illustration
                  key={illustrationConfig.type}
                  position="absolute"
                  {...illustrationConfig}
                />
              )
            })
          : null}
      </Box>
    </Box>
  )
}
