import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { Box, Flex, Image } from '@mindfulchefuk/design-system'
import { partnerships } from '@mindfulchefuk/features/Homepage/data/partnerships'

import {
  WaveIllustrationWrapper,
  WaveIllustrationWrapperProps,
} from '@mindfulchefuk/design-system/layout/WaveIllustrationWrapper'

const Carousel = styled(Flex)`
  &::-webkit-scrollbar {
    display: none;
  }
`

interface PartnershipsProps {
  data?: {
    id: string
    name: string
    image: string
    width: number
    copy: ReactElement
  }[]
  waveIllustrationWrapper?: WaveIllustrationWrapperProps
}

export const Partnerships = ({
  data = partnerships,
  waveIllustrationWrapper = {
    illustrations: {
      bottomRight: {
        type: 'cross-section-asparagus',
        styles: {
          top: { base: '45px', sm: '40px', md: '-130px' },
          right: {
            base: '-170px',
            sm: '-150px',
            md: '-230px',
            lg: '-230px',
          },
          transform: {
            base: 'scale(0.4) rotate(-46deg)',
            sm: 'scale(0.45) rotate(-46deg)',
            md: 'scale(0.7) rotate(-46deg)',
          },
        },
      },
    },
  },
}: PartnershipsProps): ReactElement => {
  return (
    <WaveIllustrationWrapper
      {...{
        bgColor: 'salt',
        waveTop: 4,
        waveBottom: 4,
        contentMaxWidth: '1000px',
        ...waveIllustrationWrapper,
      }}
    >
      <Carousel
        as="ul"
        py={12}
        ml={{ base: 20, md: 0 }}
        overflowY="hidden"
        overflowX="auto"
        alignItems="start"
      >
        {data.map((p) => (
          <Flex
            key={p.id}
            direction="column"
            minWidth={128}
            maxWidth={128}
            mx={{ base: 6, md: 16 }}
          >
            <Flex height={56} align="center">
              <Box margin="auto">
                <Image resolve src={p.image} alt={p.name} width={p.width} />
              </Box>
            </Flex>
            <Box mt={10}>{p.copy}</Box>
          </Flex>
        ))}
      </Carousel>
    </WaveIllustrationWrapper>
  )
}
