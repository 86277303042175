import React, { ReactElement } from 'react'
import { Box } from '@mindfulchefuk/design-system'
import { HeroPlateSection } from '@mindfulchefuk/features/Homepage/components/Hero/PlateSection'
import { HeroTopSection } from '@mindfulchefuk/features/Homepage/components/Hero/TopSection'
import { HeroBottomSection } from '@mindfulchefuk/features/Homepage/components/Hero/BottomSection'
import { useCMSHomepageVariant } from '@mindfulchefuk/features/CMS/hooks/useCMSHomepageVariant'

export const Hero = (): ReactElement => {
  const {
    heroQuote,
    emphasisInHeroQuote,
    heroTitle,
    heroTitleEmphasis,
    heroByline,
    plates,
  } = useCMSHomepageVariant()
  return (
    <Box as="section" mb={16} id="homepage-hero">
      <HeroTopSection
        heroTitle={heroTitle}
        heroTitleEmphasis={heroTitleEmphasis}
        heroByline={heroByline}
      />

      <HeroPlateSection plates={plates} />

      <Box pt={16} backgroundColor="salt">
        <HeroBottomSection
          ctaText={heroQuote}
          ctaTextEmphasis={emphasisInHeroQuote}
        />
      </Box>
    </Box>
  )
}
