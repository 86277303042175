import React, { ReactElement } from 'react'
import { Box, Text } from '@mindfulchefuk/design-system'
import CTA from '@mindfulchefuk/features/Homepage/components/CTA/CTA'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'
import { EmphasisLinebreakText } from '@mindfulchefuk/features/Homepage/components/EmphasisLinebreakText'
import { CTALocation } from '@mindfulchefuk/analytics'

interface BottomPageCTASectionProps {
  hideIllustrations?: boolean
  ctaLocation?: CTALocation
}

export const BottomPageCTASection = ({
  hideIllustrations = false,
  ctaLocation = 'homepage bottom of the page CTA',
}: BottomPageCTASectionProps): ReactElement => {
  const illustrationStyles = {
    'cross-section-kale': {
      bottom: { base: '-100px', sm: '-110px', md: '-90px' },
    },
    'cherry-tomato-slice': {
      bottom: { base: '-150px', md: '-125px' },
    },
  }
  return (
    <Box
      as="section"
      zIndex={1}
      my={16}
      maxWidth="800px"
      px={24}
      mx="auto"
      textAlign="center"
      position="relative"
    >
      <EmphasisLinebreakText
        py={16}
        as="h2"
        variant={{ base: 'heading-sm', sm: 'heading-md' }}
        mx="auto"
        maxWidth="500px"
        text="Reduce food waste, save time. Healthy eating made easy starts here"
        emphasisText="Healthy eating made easy"
        emphasisVariant={{ base: 'heading-md', sm: 'heading-lg' }}
      />

      <CTA
        data-testid="homepage-hero-cta"
        analytics={{
          category: ANALYTICS_CATEGORIES.homepage,
          action: 'Bottom of the page CTA clicked',
          label: 'Choose recipe button clicked',
        }}
        source={ctaLocation}
        showCopy={false}
      />
      <Text
        variant={{ base: 'body-sm', sm: 'body-md', md: 'body-lg' }}
        maxWidth="270px"
        mx="auto"
        py={12}
      >
        Fully flexible subscription, skip or pause anytime
      </Text>
      {!hideIllustrations && (
        <>
          <Illustration
            type="cherry-tomato-slice"
            left={{
              base: '-130px',
              sm: '-120px',
              md: '-170px',
            }}
            transform={{
              base: 'scale(0.25)',
              sm: 'scale(0.35)',
              md: 'scale(0.45)',
            }}
            bottom={illustrationStyles['cherry-tomato-slice'].bottom}
          />
          <Illustration
            type="cross-section-kale"
            left={{
              base: '-90px',
              md: '-160px',
            }}
            transform={{
              base: 'scale(0.4) rotate(40deg)',
              sm: 'scale(0.5) rotate(40deg)',
              md: 'scale(0.8) rotate(40deg)',
            }}
            bottom={illustrationStyles['cross-section-kale'].bottom}
          />
        </>
      )}
    </Box>
  )
}
