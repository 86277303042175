import React from 'react'
import { Box, ColorToken, Svg } from '@mindfulchefuk/design-system'
import { layers } from '@mindfulchefuk/features/Homepage/components/Hero/config'

interface PlateSectionBackgroundProps {
  bottomColor: ColorToken
}

export const PlateSectionBackground = ({
  bottomColor,
}: PlateSectionBackgroundProps) => {
  return (
    <>
      <Box
        position="absolute"
        width="100%"
        top={0}
        height={{ base: '93%', sm: '50%' }}
        backgroundColor="aubergine"
        zIndex={layers.topSection}
      />

      <Box
        position="absolute"
        width="100%"
        height={{ base: '7%', sm: '50%' }}
        bottom={0}
        backgroundColor={bottomColor}
        zIndex={layers.bottomSection}
      />

      <Box
        zIndex={layers.bottomSection}
        width="100%"
        top={{ base: '91%', sm: '50%' }}
        position="absolute"
        transform={{ sm: 'translateY(-50%)' }}
      >
        <Svg
          fill="none"
          viewBox="0 0 2390 149"
          color={bottomColor}
          display="block"
        >
          <path
            fill="currentColor"
            d="M0 0a4486.772 4486.772 0 0 0 752.756 42.064L1195 29.548l675.13-22.036c173.6-5.667 347.37 1.7 519.87 22.036V148.5H0V0Z"
          />
        </Svg>
      </Box>
    </>
  )
}
