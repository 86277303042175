import React, { ReactElement } from 'react'
import { Box, Text, ColorToken } from '@mindfulchefuk/design-system'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { EmailCaptureForm } from '@mindfulchefuk/features/Homepage/components/EmailCapture/EmailCaptureForm'
import {
  NewsletterContactFormSource,
  createNewsletterContact,
} from '@mindfulchefuk/features/Homepage/services/createNewsletterContact'
import { EmphasisLinebreakText } from '@mindfulchefuk/features/Homepage/components/EmphasisLinebreakText'
import {
  WaveIllustrationWrapper,
  WaveIllustrationWrapperProps,
} from '@mindfulchefuk/design-system/layout/WaveIllustrationWrapper'
import { getDiscountConfig } from '@mindfulchefuk/utils/getDiscountConfig'
import { useSelector } from 'react-redux'
import { RootState } from '@mindfulchefuk/types/store'

export const EmailCapture = ({
  color = 'aubergine',
  isBBCGoodFood = false,
  source = 'MC_homepage',
  waveIllustrationWrapper = {},
}: {
  backgroundColor?: ColorToken
  color?: ColorToken
  isBBCGoodFood?: boolean
  source?: NewsletterContactFormSource
  waveIllustrationWrapper?: WaveIllustrationWrapperProps
}): ReactElement => {
  const savedVoucherCode = useSelector(
    (state: RootState) => state.basket.savedVoucherCode
  )
  const discountConfig = getDiscountConfig(savedVoucherCode)

  return (
    <WaveIllustrationWrapper
      {...{
        waveTop: 5,
        waveBottom: 3,
        bgColor: 'aubergine-50',
        waveBgColor: ['white', 'aubergine'],
        ...waveIllustrationWrapper,
      }}
    >
      <Box
        color={color}
        maxWidth="680px"
        py={48}
        px={24}
        mx="auto"
        textAlign="center"
      >
        <EmphasisLinebreakText
          text={
            discountConfig?.totalDiscountSentence ||
            'Save 25% on your first 4 boxes!'
          }
          emphasisText="Save 25%"
          variant={{ base: 'heading-sm', sm: 'heading-md' }}
          emphasisVariant={{ base: 'heading-md', sm: 'heading-lg' }}
        />

        <Text
          as="p"
          variant={{ base: 'body-md', sm: 'body-lg' }}
          mt={16}
          mb={12}
        >
          Get delicious discounts, inspiration, and recipes by signing up to our
          newsletter.
        </Text>
        <EmailCaptureForm
          isBBCGoodFood={isBBCGoodFood}
          source={source}
          submitHandler={(formValues) => {
            logEvent({
              category: ANALYTICS_CATEGORIES.homepage,
              action: 'Email capture CTA clicked',
              label: 'Homepage',
            })
            return createNewsletterContact(formValues)
          }}
        />
      </Box>
    </WaveIllustrationWrapper>
  )
}
