import React, { ReactElement } from 'react'
import { Box, Icon, Text } from '@mindfulchefuk/design-system'

type PlateCalloutProps = {
  text?: string
}

export const PlateCallout = ({ text }: PlateCalloutProps): ReactElement => (
  <Box
    position="absolute"
    bottom={{ base: -44, sm: '95%', md: '90%' }}
    left={{ base: '55%', sm: '85%', md: '90%' }}
    width={{ base: '120px', sm: '144px' }}
    display="block"
    zIndex={3}
  >
    <Text
      color={{ base: 'aubergine', sm: 'white' }}
      variant={{ base: 'body-xs', sm: 'body-sm' }}
      textAlign={{ sm: 'center' }}
      position="relative"
    >
      {text}
      <Icon
        position="absolute"
        left={{ sm: '40%', base: '-32' }}
        bottom={{ sm: '-24' }}
        top={{ base: '0', sm: 'unset' }}
        type="arrow-curved"
        color={{ base: 'aubergine', sm: 'white' }}
        size={24}
        transform={{
          base: 'scaleX(-1) rotate(180deg)',
          sm: 'scaleX(-1) rotate(-114deg)',
        }}
        aria-label="arrow-icon"
      />
    </Text>
  </Box>
)
