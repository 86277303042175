import React, { VoidFunctionComponent } from 'react'
import {
  Box,
  CONTAINER_MAX_WIDTH,
  ColorToken,
  Flex,
} from '@mindfulchefuk/design-system'
import { PlateCallout } from '@mindfulchefuk/features/Homepage/components/Hero/PlateCallout'

import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'
import { useHomepageThemeIllustrations } from '@mindfulchefuk/features/Homepage/hooks/useHomepageThemeIllustrations'
import { Plate } from '@mindfulchefuk/features/Homepage/components/Hero/Plate'
import { PlateSectionBackground } from '@mindfulchefuk/features/Homepage/components/Hero/PlateSectionBackground'
import { CMSPlates } from '@mindfulchefuk/features/CMS/interfaces'

export const HeroPlateSection: VoidFunctionComponent<{
  hideIllustrations?: boolean
  bottomColor?: ColorToken
  plates: CMSPlates
}> = ({ hideIllustrations, bottomColor = 'salt', plates }) => {
  const { heroPlatesLeft, heroPlatesRight } = useHomepageThemeIllustrations()

  return (
    <Box position="relative">
      <PlateSectionBackground bottomColor={bottomColor} />
      <Box
        px="10%"
        mx="auto"
        maxWidth={CONTAINER_MAX_WIDTH}
        position="relative"
      >
        <Flex
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems="center"
          justifyContent="center"
          paddingBottom={{ base: 0, sm: 40 }}
          margin="0 auto"
          maxWidth="fit-content"
          position="relative"
          minHeight={{
            base: '230px',
            sm: '285px',
            md: '285px',
            lg: '365px',
          }}
        >
          {plates.onePersonImage && (
            <Plate
              key={1}
              portionCount={1}
              imageUrl={plates.onePersonImage.path}
              altText={plates.onePersonImage.altText}
              href={plates.onePersonUrl}
              layer={0}
            />
          )}
          {plates.twoPersonImage && (
            <Plate
              key={2}
              portionCount={2}
              imageUrl={plates.twoPersonImage.path}
              altText={plates.twoPersonImage.altText}
              href={plates.twoPersonUrl}
              layer={1}
            />
          )}
          {plates.fourPersonImage && (
            <Plate
              key={4}
              portionCount={4}
              imageUrl={plates.fourPersonImage.path}
              altText={plates.fourPersonImage.altText}
              href={plates.fourPersonUrl}
              layer={2}
            />
          )}
          {plates.calloutText && <PlateCallout text={plates.calloutText} />}
        </Flex>
        {!hideIllustrations ? (
          <>
            {heroPlatesLeft.map((illustrationConfig) => {
              return (
                <Illustration
                  key={illustrationConfig.type}
                  position="absolute"
                  {...illustrationConfig}
                />
              )
            })}

            {heroPlatesRight.map((illustrationConfig) => {
              return (
                <Illustration
                  key={illustrationConfig.type}
                  position="absolute"
                  {...illustrationConfig}
                />
              )
            })}
          </>
        ) : null}
      </Box>
    </Box>
  )
}
