import React, { ReactElement } from 'react'
import { Box, Text } from '@mindfulchefuk/design-system'
import CTA from '@mindfulchefuk/features/Homepage/components/CTA/CTA'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { useSelector } from 'react-redux'
import { RootState } from '@mindfulchefuk/types/store'
import { getDiscountConfig } from '@mindfulchefuk/utils/getDiscountConfig'
import { EmphasisLinebreakText } from '@mindfulchefuk/features/Homepage/components/EmphasisLinebreakText'
import { CTALocation } from '@mindfulchefuk/analytics'
import {
  WaveIllustrationWrapper,
  WaveIllustrationWrapperProps,
} from '@mindfulchefuk/design-system/layout/WaveIllustrationWrapper'
import { homepageVariantFallback } from '@mindfulchefuk/features/Homepage/data/homepage-variant'

interface HeroBottomSectionProps {
  ctaText?: string
  ctaTextEmphasis?: string
  hideIllustrations?: boolean
  ctaLocation?: CTALocation
  waveIllustrationWrapper?: WaveIllustrationWrapperProps
  additionalContent?: ReactElement
}

export const HeroBottomSection = ({
  ctaText = homepageVariantFallback.heroQuote,
  ctaTextEmphasis = homepageVariantFallback.emphasisInHeroQuote,
  ctaLocation = 'homepage hero plates CTA',
  waveIllustrationWrapper = {
    waveTop: null,
    waveBottom: 1,
    contentMaxWidth: 'none',
    bgColor: 'salt',
    illustrations: {
      bottomLeft: {
        type: 'cross-section-coriander-1',
        styles: {
          transform: {
            base: 'rotate(-40deg) scale(0.4)',
            sm: 'rotate(-40deg) scale(0.7)',
            md: 'rotate(-40deg) scale(0.8)',
          },
          bottom: { base: -56, sm: -48 },
          left: { base: -16, sm: 48, md: '20%', xl: '30%' },
        },
      },
    },
  },
  additionalContent = null,
}: HeroBottomSectionProps): ReactElement => {
  const savedVoucherCode = useSelector(
    (state: RootState) => state.basket.savedVoucherCode
  )
  const discountConfig = getDiscountConfig(savedVoucherCode)

  return (
    <WaveIllustrationWrapper {...waveIllustrationWrapper}>
      <Box
        width="100%"
        textAlign="center"
        pt={{ base: 16, sm: 0 }}
        pb={{ base: 16, sm: 1 }}
        px={8}
      >
        {additionalContent && additionalContent}
        <EmphasisLinebreakText
          as="p"
          text={ctaText}
          variant={{ base: 'body-lg-500', sm: 'body-xl-500' }}
          emphasisText={ctaTextEmphasis}
          emphasisVariant={{ base: 'body-xl-500', sm: 'body-xxl-500' }}
        />

        <Box mt={12}>
          <CTA
            analytics={{
              category: ANALYTICS_CATEGORIES.homepage,
              action: 'Hero CTA button clicked',
              label: 'Choose Recipes',
            }}
            source={ctaLocation}
            showCopy={false}
          />
          <Text as="p" variant="body-md" mt={8}>
            {discountConfig
              ? `Get ${discountConfig.totalDiscountSentence}`
              : 'Get 25% off your first 4 recipe boxes'}
          </Text>
        </Box>
      </Box>
    </WaveIllustrationWrapper>
  )
}
